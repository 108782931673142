import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h2>AdeleMacheda.com is under construction</h2>
      </header>
    </div>
  );
}

export default App;
